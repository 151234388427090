import { defineStore } from "pinia";
import { useLocalStorage, useNow } from "@vueuse/core";
import * as api from "@/api";
import { useJwt } from "@vueuse/integrations/useJwt";
import { computed } from "vue";
import type { LoginResponse } from "@/types/api";
import { useUserStore } from "@/stores/user";
import { type JwtPayload } from "jwt-decode";
import type { Role } from "@/constants";

interface TokenPayload extends JwtPayload {
  id: Guid;
  "http://schemas.microsoft.com/ws/2008/06/identity/claims/role": Role;
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress": string;
}

export const useAuthStore = defineStore("auth", () => {
  // State
  const accessToken = useLocalStorage("accessToken", "");
  const refreshToken = useLocalStorage("refreshToken", "");

  // Internal state
  const now = useNow();
  const { payload } = useJwt<TokenPayload>(accessToken);

  // Getters
  const isLoggedIn = computed(
    () => !!payload.value?.exp && payload.value.exp >= now.value.getTime() / 1000
  );

  // Actions
  function sendLoginEmail(email: string) {
    return api.authenticateByEmail(email);
  }

  async function login(email: string, code: string) {
    return api.authenticateWithEmailAndCode(email, code).then(async response => {
      updateTokens(response);

      const user = useUserStore();

      return user.refreshData().then(() => response);
    });
  }

  async function logout() {
    if (refreshToken.value) {
      await api.logout().catch(() => {});
    }

    resetTokens();
  }

  async function refreshTokens(forceRefresh = false) {
    await lockTokenRefresh(async () => {
      if (!refreshToken.value) {
        await logout();
        return;
      }
      if (isLoggedIn.value && !forceRefresh) {
        return;
      }

      await api
        .refreshAuthTokens(refreshToken.value)
        .then(response => updateTokens(response))
        .catch(async error => {
          if (api.isUnauthorized(error)) {
            await logout();
          }
        });
    });
  }

  function lockTokenRefresh(callback: LockGrantedCallback) {
    return navigator.locks.request("refresh-tokens-lock", callback);
  }

  // Internal methods
  function updateTokens({ data }: LoginResponse) {
    accessToken.value = data.accessToken;
    refreshToken.value = data.refreshToken;
  }

  function resetTokens() {
    accessToken.value = "";
    refreshToken.value = "";
  }

  return {
    // State
    accessToken,
    refreshToken,

    // Getters
    isLoggedIn,

    // Actions
    sendLoginEmail,
    login,
    logout,
    refreshTokens,
    lockTokenRefresh,

    $reset: resetTokens
  };
});
