import { AccessLevel } from "@/constants";
import { routeMeta, hasAccess } from "@/utils/routing";
import type { RouteRecordRaw } from "vue-router";

const ErrorNotFound = () => import("@/views/ErrorNotFound.vue");

const routes: RouteRecordRaw[] = [
  {
    path: "/:catchAll(.*)",
    name: "ErrorNotFound",
    component: ErrorNotFound,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.LoggedIn
    }),
    beforeEnter: [hasAccess]
  }
];

export default routes;
