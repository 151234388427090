import type {
  BuildingAccessGroupInvite,
  BulkAcceptBuildingAccessGroupInviteRequest,
  BulkAcceptBuildingAccessGroupInviteResponse,
  BulkCreateBuildingAccessGroupInviteRequest,
  BulkCreateBuildingAccessGroupInviteResponse,
  BulkRevokeBuildingAccessGroupInviteRequest,
  BulkRevokeBuildingAccessGroupInviteResponse,
  GetBuildingAccessGroupInvitesResponse,
  SearchRequest
} from "@/types";
import { axios, useCache } from "../axios";
import { SortOrder } from "@/constants";
import type { SearchResponse } from "@/types/api";
import type { CacheRequestConfig } from "axios-cache-interceptor";

export const getBuildingAccessGroupInvites = (
  config: CacheRequestConfig<GetBuildingAccessGroupInvitesResponse> = {}
) => {
  return axios
    .get<GetBuildingAccessGroupInvitesResponse>("api/BuildingAccessGroupInvite", {
      cache: useCache({ ttl: 15 * 60 * 1000 }),
      ...config
    })
    .then(response => response.data);
};

export const inviteUsersToBuildingAccessGroup = (
  accessGroupId: Guid,
  data: BulkCreateBuildingAccessGroupInviteRequest
) => {
  return axios
    .post<
      BulkCreateBuildingAccessGroupInviteResponse,
      BulkCreateBuildingAccessGroupInviteRequest
    >(`api/BuildingAccessGroupInvite/${accessGroupId}`, data)
    .then(response => response.data);
};

export const searchBuildingAccessGroupInvites = (
  accessGroupId: Guid,
  request: Partial<SearchRequest>
) => {
  return axios
    .post<SearchResponse<BuildingAccessGroupInvite>, SearchRequest>(
      `api/BuildingAccessGroupInvite/${accessGroupId}/search`,
      {
        pageNumber: request.pageNumber || 1,
        pageSize: request.pageSize || 100,
        criteria: request.criteria || [],
        field: request.field || "User.Email",
        order: request.order || SortOrder.Ascending
      }
    )
    .then(response => response.data);
};

export const revokeBuildingAccessGroupInvites = (accessGroupId: Guid, inviteIds: Guid[]) => {
  return axios
    .post<BulkRevokeBuildingAccessGroupInviteResponse, BulkRevokeBuildingAccessGroupInviteRequest>(
      `api/BuildingAccessGroupInvite/${accessGroupId}/revoke`,
      {
        inviteIds
      }
    )
    .then(response => response.data);
};

export const acceptBuildingAccessGroupInvites = (inviteIds: Guid[]) => {
  return axios
    .post<BulkAcceptBuildingAccessGroupInviteResponse, BulkAcceptBuildingAccessGroupInviteRequest>(
      "api/BuildingAccessGroupInvite/accept",
      {
        invites: inviteIds.reduce<BulkAcceptBuildingAccessGroupInviteRequest["invites"]>(
          (acc, inviteId) => {
            acc[inviteId] = true;
            return acc;
          },
          {}
        )
      }
    )
    .then(response => response.data);
};

export const declineBuildingAccessGroupInvites = (inviteIds: Guid[]) => {
  return axios
    .post<BulkAcceptBuildingAccessGroupInviteResponse, BulkAcceptBuildingAccessGroupInviteRequest>(
      "api/BuildingAccessGroupInvite/accept",
      {
        invites: inviteIds.reduce<BulkAcceptBuildingAccessGroupInviteRequest["invites"]>(
          (acc, inviteId) => {
            acc[inviteId] = false;
            return acc;
          },
          {}
        )
      }
    )
    .then(response => response.data);
};
