<template>
  <Toast position="top-right" :auto-z-index="false">
    <template #icon="{ class: iconClass }">
      <base-icon :icon="getIconName(iconClass)" :class="iconClass" />
    </template>

    <template #closeicon="{ class: iconClass }">
      <base-icon :icon="IconX" :class="iconClass" />
    </template>
  </Toast>
</template>

<script setup lang="ts">
import Toast from "primevue/toast";
import BaseIcon from "@/components/BaseIcon.vue";
import {
  type Icon,
  IconAlertHexagon,
  IconAlertTriangle,
  IconCheck,
  IconInfoCircle,
  IconX
} from "@tabler/icons-vue";

const icons: Record<string, Icon> = {
  info: IconInfoCircle,
  success: IconCheck,
  warn: IconAlertHexagon,
  error: IconAlertTriangle
};

// Hopefully temporary till the icon slot exposes 'severity'
function getIconName(iconClass: string) {
  const severity = iconClass
    .split(" ")
    .filter(item => ["info", "success", "warn", "error"].includes(item))[0];

  return icons[severity];
}
</script>
