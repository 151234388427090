// Object.fromEntries()
if (!Object.fromEntries) {
  Object.defineProperty(Object, "fromEntries", {
    value(entries) {
      if (!entries || !entries[Symbol.iterator]) {
        throw new Error("Object.fromEntries() requires a single iterable argument");
      }

      const o = {};

      Object.keys(entries).forEach(key => {
        const [k, v] = entries[key];

        o[k] = v;
      });

      return o;
    }
  });
}

// Array.prototype.flatMap()
if (!Array.prototype.flatMap) {
  Array.prototype.flatMap = function (callback, thisArg) {
    return this.reduce((acc, cur, index, array) => {
      const flattened = Array.isArray(cur) ? cur.reduce((a, c) => a.concat(c), []) : [cur];
      return acc.concat(callback.call(thisArg, flattened, index, array));
    }, []);
  };
}

// Promise.allSettled
if (!Promise.allSettled) {
  Promise.allSettled = promises =>
    Promise.all(
      promises.map(promise =>
        promise
          .then(value => ({ status: "fulfilled", value }))
          .catch(reason => ({ status: "rejected", reason }))
      )
    );
}
