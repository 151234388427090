import { AccessLevel, License } from "@/constants";
import { useAuthStore } from "@/stores/auth";
import { useUserStore } from "@/stores/user";
import { useBuildingStore } from "@/stores/building";
import type {
  RouteLocationNormalized,
  RouteLocationNormalizedGeneric,
  RouteLocationRaw,
  RouteLocationResolvedGeneric,
  RouteMeta
} from "vue-router";
import { router } from "@/router";

/** Check if user has access to the route */
export const hasAccess = (to: RouteLocationNormalizedGeneric | RouteLocationResolvedGeneric) => {
  const auth = useAuthStore();
  const user = useUserStore();

  const errorPage: RouteLocationRaw = {
    path: "/404",
    query: { fromUrl: to.fullPath }
  };

  switch (to.meta.minimumAccessLevel) {
    case AccessLevel.Admin:
      return user.isAdmin || errorPage;
    case AccessLevel.LoggedIn:
      return auth.isLoggedIn || errorPage;
    case AccessLevel.BuildingAccessGroupUser:
      return user.hasAccessToBuilding(to.params.buildingId as Guid) || errorPage;
    case AccessLevel.BuildingUser:
      return (
        (to.params.buildingId
          ? user.isBuildingUser(to.params.buildingId as Guid)
          : user.isBuildingUserSomewhere(to.meta.requiredLicense)) || errorPage
      );
    case AccessLevel.Public:
      return true;
    default:
      return errorPage;
  }
};

/** Check by building license whether user has access and routes accordingly */
export const checkLicenseAndRoute = (
  to: RouteLocationNormalized,
  pageName: string
): RouteLocationRaw | true => {
  const building = useBuildingStore();

  if (!building.id) {
    return {
      path: "/404",
      query: { fromUrl: to.fullPath }
    };
  } else if (
    hasAccessToPageWithLicense(
      router.resolve({
        name: pageName,
        params: { buildingId: to.params.buildingId }
      })
    )
  ) {
    return {
      name: pageName,
      params: { buildingId: to.params.buildingId }
    } as RouteLocationRaw;
  } else {
    return true;
  }
};

export const hasAccessToPageWithLicense = (
  to: RouteLocationNormalizedGeneric | RouteLocationResolvedGeneric
) => {
  const license = to.meta.requiredLicense;

  if (license === null) {
    return true;
  } else if (!to.params.buildingId) {
    const user = useUserStore();

    return user.sortedBuildings.some(building => building.licenses.includes(license));
  }

  // Global navigation guard makes sure that building is loaded before routing
  // as long as that guard is called before this function, we can safely assume
  // that the building store is loaded and matches the buildingId in the route
  const building = useBuildingStore();

  if (!building.hasLicense(license)) {
    return false;
  }

  // TODO: when we have a better way of retrieving connections for new buildings
  // we can remove this check
  const user = useUserStore();

  if (user.isAdmin) {
    return true;
  }

  switch (to.meta.requiredLicense) {
    case License.ESG:
      return building.connections.length > 0;
    case License.Health:
      return building.spaces.length > 0;
    default:
      return true;
  }
};

export const routeMeta = (options: Partial<RouteMeta> = {}): RouteMeta => {
  return {
    breadcrumbs: options.breadcrumbs || [],
    requiredLicense: options.requiredLicense ?? null,
    queryParams: options.queryParams || null,
    minimumAccessLevel: options.minimumAccessLevel || AccessLevel.Admin
  };
};
