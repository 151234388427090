import { License, SensorType, SystemType, UtilityType, WEiiClass } from "@/constants";
import { compareAsc } from "date-fns";
import type { EnergyLabelLetter } from "@/types";

export const compareNumbers = (a: number | null, b: number | null) => {
  const NULL_VALUE = -0.00001;

  return (a ?? NULL_VALUE) - (b ?? NULL_VALUE);
};

export const compareStrings = (a: string | null, b: string | null) =>
  (a || "").localeCompare(b || "");

export const compareBooleans = (a: boolean | null, b: boolean | null) => {
  const valueA = a === null ? -1 : a ? 1 : 0;
  const valueB = b === null ? -1 : b ? 1 : 0;

  return valueA - valueB;
};

export const compareDates = compareAsc;

export const compareSensors = (a: SensorType, b: SensorType) => {
  const order = [
    SensorType.Temperature,
    SensorType.CarbonDioxide,
    SensorType.Humidity,
    SensorType.VOC,
    SensorType.Formaldehyde,
    SensorType.PM1,
    SensorType.PM25,
    SensorType.PM4,
    SensorType.PM10,
    SensorType.CarbonMonoxide,
    SensorType.Ozone,
    SensorType.NitrogenDioxide,
    SensorType.Sound
  ];

  return order.indexOf(a) - order.indexOf(b);
};

export const compareUtilities = (a: UtilityType, b: UtilityType) => {
  const order = [
    UtilityType.Electricity,
    UtilityType.ElectricityReturn,
    UtilityType.ElectricityGenerated,
    UtilityType.Gas,
    UtilityType.DistrictHeating,
    UtilityType.DistrictCooling,
    UtilityType.Heating,
    UtilityType.Cooling,
    UtilityType.Water
  ];

  return order.indexOf(a) - order.indexOf(b);
};

export const compareSystems = (a: SystemType, b: SystemType) => {
  const order = [
    SystemType.AirHandlingUnit,
    SystemType.Boiler,
    SystemType.Chiller,
    SystemType.HeatPump,
    SystemType.PrimaryLoop,
    SystemType.SecondaryLoop
  ];

  return order.indexOf(a) - order.indexOf(b);
};

export const compareLicenses = (a: License, b: License) => {
  const order = [License.ESG, License.Health, License.Bms];

  return order.indexOf(a) - order.indexOf(b);
};

export const compareEnergyLabels = (a: EnergyLabelLetter | null, b: EnergyLabelLetter | null) => {
  // prettier-ignore
  const order: (EnergyLabelLetter | null)[] = ["A+++++", "A++++", "A+++", "A++", "A+", "A", "B", "C", "D", "E", "F", "G", null];

  return order.indexOf(a) - order.indexOf(b);
};

export const compareWEiiClasses = (a: WEiiClass, b: WEiiClass) => {
  const order = [
    WEiiClass.Weng,
    WEiiClass.ParisProof,
    WEiiClass.VeryEfficient,
    WEiiClass.Efficient,
    WEiiClass.Average,
    WEiiClass.Inefficient,
    WEiiClass.VeryInefficient
  ];

  return order.indexOf(a) - order.indexOf(b);
};
