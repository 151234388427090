import type { AxiosInstance } from "axios";
import type { CacheRequestConfig } from "axios-cache-interceptor";
import { useAuthStore } from "@/stores/auth";
import { router } from "@/router";
import { unref } from "vue";

export const useInterceptors = (axios: AxiosInstance) => {
  // Before every request, check if the user is logged in and has a valid token
  // If not, try to refresh the token
  axios.interceptors.request.use(
    async config => {
      const auth = useAuthStore();

      if (!auth.accessToken || config.headers.Authorization === null) {
        return config;
      }

      config.headers.Authorization = `Bearer ${auth.accessToken}`;

      return config;
    },
    error => Promise.reject(error)
  );

  // Retry requests when the API returns a '401 Unauthorized' error
  axios.interceptors.response.use(
    response => response,
    async error => {
      const originalRequest = error.config as CacheRequestConfig;

      if (
        error.response?.status === 401 &&
        originalRequest.url !== "api/Auth/refresh-token" &&
        originalRequest.url !== "api/Auth/logout"
      ) {
        const auth = useAuthStore();

        const currentRoute = unref(router.currentRoute);
        const routeName = currentRoute.name;
        const routeQuery = {
          ...currentRoute.query,
          redirect: currentRoute.path
        };

        await auth.refreshTokens(!!error.config._retry);

        if (auth.isLoggedIn && (!error.config._retry || error.config._retry < 3)) {
          const newRequest = {
            ...originalRequest,
            headers: {
              ...originalRequest.headers
            },
            cache: {
              ...originalRequest.cache,
              override: true
            },
            _retry: !error.config._retry ? 1 : error.config._retry + 1
          };

          return axios.request(newRequest);
        } else if (routeName !== "UserLogin") {
          auth.$reset();
          router.push({ name: "UserLogin", query: routeQuery });
        }
      }

      return Promise.reject(error);
    }
  );
};
