export const apiUrl = () => {
  if (window.location.hostname === import.meta.env.VITE_STAGING_HOST) {
    return import.meta.env.VITE_STAGING_API_URL;
  }

  return import.meta.env.VITE_API_URL;
};

export const env = () => {
  if (window.location.hostname === import.meta.env.VITE_STAGING_HOST) {
    return "staging";
  }

  return import.meta.env.MODE;
};
