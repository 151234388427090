import { apiUrl } from "@/environment";
import Axios from "axios";
import { buildWebStorage, setupCache, type CacheProperties } from "axios-cache-interceptor";
import { useInterceptors } from "./interceptors";
import clone from "just-clone";

// Axios instance with cache
export const axios = (() => {
  const axiosInstance = Axios.create({
    baseURL: apiUrl()
  });

  useInterceptors(axiosInstance);

  return setupCache(axiosInstance, {
    ttl: 4 * 60 * 60 * 1000, // 4 hours
    methods: ["get", "post"],
    storage: buildWebStorage(sessionStorage, "hw-cache:")
  });
})();

// Cache helper
export const useCache = (() => {
  // Reset cache defaults to false
  const cacheOptions = axios.defaults.cache;
  (axios.defaults.cache as false | CacheProperties) = false;

  return <R = unknown, D = unknown>(
    options: Partial<CacheProperties<R, D>> = {}
  ): Partial<CacheProperties<R, D>> => {
    const defaultOptions = clone(cacheOptions);

    return deepMerge(defaultOptions, options);
  };
})();

/**
 * Deep merge two objects.
 * - If a property is an object, it will be merged recursively.
 * - If a property is an array, it will be replaced.
 * - If a property is a primitive, it will be replaced.
 * - If a property doesn't exist in target, it will be added.
 *
 * Use with caution: This function mutates target and modifying source could also modify target.
 */
function deepMerge(target: any, source: any) {
  for (const key in source) {
    if (Object.prototype.hasOwnProperty.call(source, key)) {
      if (
        key in target &&
        target[key] !== null &&
        typeof target[key] === "object" &&
        source[key] !== null &&
        typeof source[key] === "object"
      ) {
        deepMerge(target[key], source[key]);
      } else {
        target[key] = source[key];
      }
    }
  }
  return target;
}
