import { computed, ref } from "vue";
import { defineStore } from "pinia";
import * as api from "@/api";
import { Language, License, Role } from "@/constants";
import { changeLocale } from "@/utils/i18n";
import { compareStrings } from "@/utils/sort";
import type { Building, User, UserSettings } from "@/types";

interface BuildingData {
  building: Building;
  canManageAccessGroups: boolean;
  canManageBuildingUsers: boolean;
  hasWriteAccess: boolean;
  isBuildingUser: boolean;
}

export const useUserStore = defineStore("user", () => {
  // State
  const userId = ref<User["id"]>("" as Guid);
  const firstName = ref<User["firstname"]>("");
  const lastName = ref<User["lastname"]>("");
  const email = ref<User["email"]>("");
  const role = ref(Role.User);
  const settings = ref<User["settings"] | null>(null);

  const buildings = ref(new Map<Guid, BuildingData>());

  // Getters
  const fullName = computed(() => `${firstName.value} ${lastName.value}`);

  const isAdmin = computed(() => role.value === Role.Admin);
  const isBuildingUser = (buildingId: Guid) =>
    buildings.value.get(buildingId)?.isBuildingUser ?? false;
  const hasWriteAccess = (buildingId: Guid) =>
    buildings.value.get(buildingId)?.hasWriteAccess ?? false;
  const canManageAccessGroups = (buildingId: Guid) =>
    buildings.value.get(buildingId)?.canManageAccessGroups ?? false;
  const canManageBuildingUsers = (buildingId: Guid) =>
    buildings.value.get(buildingId)?.canManageBuildingUsers ?? false;
  const hasAccessToBuilding = (buildingId: Guid) => buildings.value.has(buildingId);
  const isBuildingUserSomewhere = (license: License | null = null) => {
    const predicate =
      license === null
        ? ({ isBuildingUser }: BuildingData) => isBuildingUser
        : ({ isBuildingUser, building }: BuildingData) =>
            isBuildingUser && building.licenses.includes(license);

    return [...buildings.value.values()].some(predicate);
  };

  const sortedBuildings = computed(() =>
    [...buildings.value.values()]
      .map(_ => _.building)
      .sort((a, b) => compareStrings(a.label || a.name, b.label || b.name))
  );
  const getBuilding = (buildingId: Guid) => buildings.value.get(buildingId)?.building ?? null;

  // Actions
  function refreshData(...args: Parameters<typeof api.getAuthenticatedUser>) {
    return api
      .getAuthenticatedUser(...args)
      .then(result => {
        const data = result.data || {};

        // Set user data
        if (settings.value?.language !== data.settings.language) {
          changeLocale(data.settings.language);
        }

        userId.value = data.settings.userId || ("" as Guid);
        role.value = data.role || Role.User;
        email.value = data.email || "";
        firstName.value = data.firstName || "";
        lastName.value = data.lastName || "";
        settings.value = data.settings || null;

        buildings.value = new Map(
          data.buildingAccessInformation.map(
            ({
              buildingInformation,
              canManageAccessGroups,
              canManageBuildingUsers,
              isBuildingUser,
              hasWriteAccess
            }) => [
              buildingInformation.id,
              {
                building: buildingInformation,
                canManageAccessGroups,
                canManageBuildingUsers,
                hasWriteAccess,
                isBuildingUser
              }
            ]
          )
        );

        return result;
      })
      .catch(error => error);
  }

  async function updateSettings(updatedSettings: Partial<UserSettings>) {
    const settingsId = settings.value?.id;

    const data = {
      userId: userId.value,
      isOnBoarded: updatedSettings.isOnBoarded ?? !!settings.value?.isOnBoarded,
      language: updatedSettings.language ?? settings.value?.language ?? Language.en_US,
      monthlyHealthReport:
        updatedSettings.monthlyHealthReport ?? settings.value?.monthlyHealthReport ?? false,
      monthlyUsageReport:
        updatedSettings.monthlyUsageReport ?? settings.value?.monthlyUsageReport ?? false,
      healthCheckAlert:
        updatedSettings.healthCheckAlert ?? settings.value?.healthCheckAlert ?? false,
      healthCheckTypes: updatedSettings.healthCheckTypes ?? settings.value?.healthCheckTypes ?? [],
      alertDays:
        updatedSettings.alertDays ??
        settings.value?.alertDays ??
        "Monday,Tuesday,Wednesday,Thursday,Friday",
      alertHours: updatedSettings.alertHours ?? settings.value?.alertHours ?? "09:00-18:00"
    };

    return (
      settingsId ? api.updateUserSettings({ id: settingsId, ...data }) : api.addUserSettings(data)
    ).then(async response => {
      settings.value = response.data;

      api.storage.remove("user");
    });
  }

  function updateBuilding(buildingId: Guid, data: Building) {
    const buildingData = buildings.value.get(buildingId);

    if (buildingData) {
      Object.assign(buildingData.building, data);
    }
  }

  function getBuildingTimeZone(buildingId: Guid) {
    const buildingData = buildings.value.get(buildingId);

    return buildingData?.building.timezone;
  }

  return {
    // State
    id: userId,
    firstName,
    lastName,
    email,
    role,
    settings,
    buildings,

    // Getters
    fullName,
    isAdmin,
    canManageAccessGroups,
    canManageBuildingUsers,
    hasWriteAccess,
    isBuildingUser,
    hasAccessToBuilding,
    isBuildingUserSomewhere,
    sortedBuildings,
    getBuilding,

    // Actions
    refreshData,
    updateSettings,
    updateBuilding,
    getBuildingTimeZone,

    $reset: () => {
      userId.value = "" as Guid;
      firstName.value = "";
      lastName.value = "";
      email.value = "";
      role.value = Role.User;
      settings.value = null;
      buildings.value = new Map();
    }
  };
});
