import type { ApplicationResponse, AuthUserResponse, SearchResponse } from "@/types/api";
import { axios, useCache } from "../axios";
import { SearchOperation, SearchCondition, SortOrder } from "@/constants";
import type {
  CreateUserRequest,
  CreateUserResponse,
  SearchRequest,
  UpdateUserRequest,
  UpdateUserResponse,
  User
} from "@/types";
import type { CacheRequestConfig } from "axios-cache-interceptor";

export const getAuthenticatedUser = (config: CacheRequestConfig<AuthUserResponse> = {}) => {
  return axios
    .get<AuthUserResponse>("api/Auth/user", {
      id: "user",
      cache: useCache({ ttl: 60 * 60 * 1000 }),
      ...config
    })
    .then(response => response.data);
};

export const createUser = (data: CreateUserRequest) => {
  return axios
    .post<CreateUserResponse, CreateUserRequest>("api/administration/User", data)
    .then(response => response.data);
};

export const updateUser = (data: UpdateUserRequest) => {
  return axios
    .put<UpdateUserResponse, UpdateUserRequest>("api/administration/User", data)
    .then(response => response.data);
};

export const searchUsers = (
  query = "",
  pageNumber = 1,
  pageSize = 100,
  field = "email",
  order = SortOrder.Ascending
) => {
  return axios
    .post("api/administration/User/search", {
      pageNumber,
      pageSize,
      criteria: [
        {
          field: "id",
          value: query,
          operation: SearchOperation.Equal,
          condition: SearchCondition.Or
        },
        {
          field: "firstname",
          value: query,
          operation: SearchOperation.Contains,
          condition: SearchCondition.Or
        },
        {
          field: "lastname",
          value: query,
          operation: SearchOperation.Contains,
          condition: SearchCondition.Or
        },
        {
          field: "email",
          value: query,
          operation: SearchOperation.Contains,
          condition: SearchCondition.Or
        }
      ],
      field,
      order
    })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return {
        succeeded: false,
        status: error.response ? error.response.status : "",
        statusText: error.response ? error.response.statusText : ""
      };
    });
};

export const searchUsersV2 = (request: Partial<SearchRequest>) => {
  return axios
    .post<SearchResponse<User>>("api/administration/User/search", {
      pageNumber: request.pageNumber || 1,
      pageSize: request.pageSize || 100,
      criteria: request.criteria || [],
      field: request.field || "email",
      order: request.order || SortOrder.Ascending
    })
    .then(response => response.data);
};

export const roles = () => {
  return axios
    .get<ApplicationResponse>("api/administration/Application", {
      cache: useCache()
    })
    .then(response => response.data.data.roles);
};

export const languages = () => {
  return axios
    .get<ApplicationResponse>("api/administration/Application", {
      cache: useCache()
    })
    .then(response => {
      return response.data.data.languages;
    })
    .catch(error => {
      return {
        succeeded: false,
        status: error.response ? error.response.status : "",
        statusText: error.response ? error.response.statusText : ""
      };
    });
};

export const getBrandings = () => {
  return axios
    .get<ApplicationResponse>("api/administration/Application", {
      cache: useCache()
    })
    .then(response => response.data.data.brandings);
};

export const getUsersByBuildingAccessGroupId = (buildingAccessGroupId: Guid) => {
  return axios
    .get<User[]>("api/administration/User/GetByBuildingAccessGroupId", {
      params: { buildingAccessGroupId }
    })
    .then(response => response.data);
};

export const getUserAcquaintances = (request: Partial<SearchRequest>) => {
  return axios
    .post<SearchResponse<{ email: string; name: string }>, SearchRequest>(
      `api/User/acquaintances`,
      {
        pageNumber: request.pageNumber || 1,
        pageSize: request.pageSize || 100,
        criteria: request.criteria || [],
        field: request.field || "User.Email",
        order: request.order || SortOrder.Ascending
      }
    )
    .then(response => response.data);
};
