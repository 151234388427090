import type { SearchRequest } from "@/types";
import type { SearchResponse } from "@/types/api";
import { format, isAfter, isValid, max as maxDate, min as minDate } from "date-fns";
import { toDate } from "date-fns-tz";

/**
 * Round a number to some decimals
 * @param {Number} num
 * @param {Number} decimals number of decimals the value should be rounded to
 * @returns {Number}
 */
export const roundNumber = (num: number, decimals = 0) => {
  if (typeof num !== "number") return num;

  const multiplier = Math.pow(10, decimals);

  return Math.round(num * multiplier) / multiplier;
};

export const inverseObject = (obj: object) => {
  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => {
      return [value, key];
    })
  );
};

export const findKeyByValue = <T extends Record<string, unknown>>(obj: T, value: T[keyof T]) => {
  const key = Object.keys(obj).find(key => obj[key] === value);

  return key ? (key as keyof T) : null;
};

export const isEqualObjects = (obj1: object, obj2: object) => {
  const entries1 = Object.entries(obj1);
  const entries2 = Object.entries(obj2);

  if (entries1.length !== entries2.length) {
    return false;
  }

  for (let i = 0; i < entries1.length; ++i) {
    // Keys
    if (entries1[i][0] !== entries2[i][0]) {
      return false;
    }

    // Values
    if (entries1[i][1] !== entries2[i][1]) {
      return false;
    }
  }

  return true;
};

// Assumes values of both arrays are unique and primitive
export const isEqualArrays = (arr1: Array<any>, arr2: Array<any>, checkOrder = false) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2) || arr1.length !== arr2.length) {
    return false;
  }

  if (checkOrder) {
    return arr1.every((value, idx) => arr2[idx] === value);
  } else {
    return arr1.every(value => arr2.includes(value));
  }
};

/**
 * Get new date range adjusted to set limits
 */
export const getDatesWithinLimits = (dates: DateRange, limits: DateRange): DateRange => {
  const startDates = [dates[0], limits[0]].filter(date => isValid(date));
  const endDates = [dates[1], limits[1]].filter(date => isValid(date));

  return [maxDate(startDates), minDate(endDates)];
};

export const isDateRangeValid = (dateRange: unknown): dateRange is [Date, Date] => {
  return (
    Array.isArray(dateRange) &&
    dateRange.length === 2 &&
    isValid(dateRange[0]) &&
    isValid(dateRange[1]) &&
    isAfter(dateRange[1], dateRange[0])
  );
};

export const tw = (strings: TemplateStringsArray, ...keys: string[]) => {
  const lastIndex = strings.length - 1;

  return (
    strings.slice(0, lastIndex).reduce((acc, str, i) => {
      return acc + str + keys[i];
    }, "") + strings[lastIndex]
  );
};

/**
 * A factory function that returns a function that maps items to keys.
 * The keys are unique and start from 1.
 * Note: the items have to be unique.
 */
export const keyMapperFactory = <T = any>() => {
  const keys = new Map<T, number>();
  let i = 1;

  return (item: T) => {
    if (!keys.has(item)) {
      keys.set(item, i++);
    }

    return keys.get(item) as number;
  };
};

export const hexToRGB = (hex: `#${string}`, alpha = 1) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const searchRecursively = <T>(
  fn: (data: Partial<SearchRequest>) => Promise<SearchResponse<T>>,
  data?: Partial<SearchRequest>
): Promise<T[]> => {
  function search(pageNumber: number): Promise<T[]> {
    return fn({ ...data, pageNumber }).then(async response => {
      if (response.nextPage) {
        return [...response.data, ...(await search(response.nextPage))];
      } else {
        return response.data;
      }
    });
  }

  return search(data?.pageNumber || 1);
};

export const retryFn = <T extends (...args: any[]) => Promise<any>>(fn: T, numberOfRetries = 1) => {
  const wrapper = (...args: Parameters<T>): ReturnType<T> => {
    return fn(...args).catch(error => {
      if (numberOfRetries === 0) {
        return Promise.reject(error);
      }

      numberOfRetries--;

      return wrapper(...args);
    }) as ReturnType<T>;
  };

  return wrapper;
};

export const isPromiseFulfilled = <T>(
  response: PromiseSettledResult<T>
): response is PromiseFulfilledResult<T> => response.status === "fulfilled";

export const isPromiseRejected = <T>(
  response: PromiseSettledResult<T>
): response is PromiseRejectedResult => response.status === "rejected";

export const getCurrentYear = () => new Date().getFullYear();

export const convertToTimeZone = (date: Date, timeZone?: string) => {
  const formattedDate = format(date, "yyyy-MM-dd'T'HH:mm:ss");

  return toDate(formattedDate, { timeZone });
};

/** Object.fromEntries with type safety */
export const typedObjectFromEntries = <
  const T extends ReadonlyArray<readonly [PropertyKey, unknown]>
>(
  entries: T
): { [K in T[number] as K[0]]: K[1] } => {
  return Object.fromEntries(entries) as { [K in T[number] as K[0]]: K[1] };
};

export const downloadBlob = (blob: Blob, filename: string) => {
  const blobUrl = URL.createObjectURL(blob);

  const aTag = document.createElement("a");
  aTag.href = blobUrl;
  aTag.download = filename;
  document.body.appendChild(aTag);
  aTag.click();
  aTag.remove();

  URL.revokeObjectURL(blobUrl);
};
