import type {
  CreateProjectSettingsRequest,
  CreateProjectSettingsResponse,
  UpdateProjectSettingsRequest,
  UpdateProjectSettingsResponse
} from "@/types";
import { axios } from "../axios";

export const getProjectSettings = (projectSettingsId: Guid) => {
  return axios
    .get(`api/administration/ProjectSettings/${projectSettingsId}`)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return {
        succeeded: false,
        status: error.response ? error.response.status : "",
        statusText: error.response ? error.response.statusText : ""
      };
    });
};

export const addProjectSettings = (
  data: PartialWithRequired<CreateProjectSettingsRequest, "projectId">
) => {
  return axios
    .post<CreateProjectSettingsResponse, CreateProjectSettingsRequest>(
      "api/administration/ProjectSettings",
      {
        projectId: data.projectId,
        isBuildingDashboardPublic: data.isBuildingDashboardPublic || false,
        isSpaceDashboardPublic: data.isSpaceDashboardPublic || false,
        openingHours: data.openingHours || {
          mondayStartHour: "08:00",
          mondayEndHour: "18:00",
          tuesdayStartHour: "08:00",
          tuesdayEndHour: "18:00",
          wednesdayStartHour: "08:00",
          wednesdayEndHour: "18:00",
          thursdayStartHour: "08:00",
          thursdayEndHour: "18:00",
          fridayStartHour: "08:00",
          fridayEndHour: "18:00",
          saturdayStartHour: null,
          saturdayEndHour: null,
          sundayStartHour: null,
          sundayEndHour: null
        }
      }
    )
    .then(response => response.data);
};

export const updateProjectSettings = (data: UpdateProjectSettingsRequest) => {
  return axios
    .put<
      UpdateProjectSettingsResponse,
      UpdateProjectSettingsRequest
    >("api/administration/ProjectSettings", data)
    .then(response => response.data);
};
