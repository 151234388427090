import { defineStore } from "pinia";
import { useAsyncState, useLocalStorage } from "@vueuse/core";
import * as api from "@/api";

export const useGeneralStore = defineStore("general", () => {
  const visitedWatchers = useLocalStorage<{ [key: string]: boolean }>("visitedWatchers", {});

  const { state: numberOfInvites } = useAsyncState(
    () =>
      Promise.all([
        api.getBuildingUserInvites().then(response => response.invites.length),
        api.getBuildingAccessGroupInvites().then(response => response.invites.length)
      ]).then(inviteCounts => inviteCounts.reduce((acc, count) => acc + count, 0)),
    0,
    { delay: 500 }
  );

  function updateVisitedWatchers(newWatchers: { [key: string]: boolean }) {
    visitedWatchers.value = newWatchers;
  }

  return {
    numberOfInvites,
    updateNumberOfInvites: (count: number) => {
      numberOfInvites.value = count;
    },
    visitedWatchers,
    updateVisitedWatchers
  };
});
