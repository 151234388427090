import { axios, useCache } from "../axios";
import { useUserStore } from "@/stores/user";
import { Period, SortOrder } from "@/constants";
import type {
  AuthUserResponse,
  EnergyResponse,
  EnergyRuntimeResponse,
  SearchResponse
} from "@/types/api";
import type {
  Building,
  BuildingFacilityInformation,
  GetBuildingFloorsAndSpacesResponse,
  GetBuildingResponse,
  GetBuildingWeatherDataRequest,
  GetBuildingWeatherDataResponse,
  GetMeteringPointsRequest,
  GetMeteringPointsResponse,
  GetProjectDataResponse,
  InitializeBuildingRequest,
  InitializeBuildingResponse,
  SearchRequest,
  UpdateBuildingRequest,
  UpdateBuildingResponse
} from "@/types";
import type { CacheRequestConfig } from "axios-cache-interceptor";
import { convertToTimeZone } from "@/utils";
import type { CreateBuildingFacilityInformationResponse } from "@/types/api/CreateBuildingFacilityInformationResponse";
import type { CreateBuildingFacilityInformationRequest } from "@/types/api/CreateBuildingFacilityInformationRequest";
import type { UpdateBuildingFacilityInformationRequest } from "@/types/api/UpdateBuildingFacilityInformationRequest";

export const initializeBuilding = async (data: InitializeBuildingRequest) => {
  return axios
    .post<InitializeBuildingResponse, InitializeBuildingRequest>("v2/initialize-building", data, {
      cache: useCache({
        update: {
          // Someone else might have changed some data for this building
          // so we need to update the building in the userStore and user cache
          // when we retrieve the projectData
          user: (userCacheResponse, initializeBuildingResponse) => {
            if (userCacheResponse.state !== "cached") return "ignore";

            const userCache = userCacheResponse.data.data as AuthUserResponse;
            const buildings = userCache.data.buildingAccessInformation;
            const data = initializeBuildingResponse.data;
            const newBuilding: Building = {
              id: data.building.id,
              projectId: data.project.id,
              referenceId: data.project.referenceId,
              buildingFacilityInformationId: null,
              brandingId: data.building.brandingId,
              name: data.building.name,
              label: data.building.label,
              address: data.building.address,
              type: data.building.buildingType,
              country: data.building.country,
              timezone: data.building.timezone,
              licenses: data.building.licenses ?? [],
              areas: data.areas,
              addresses: data.addresses.map(address => ({
                ...address,
                houseNumberAddition: address.houseNumberAddition ?? null,
                houseLetter: address.houseLetter ?? null,
                detailIndication: address.detailIndication ?? null,
                energyLabel: null
              })),
              logo: null,
              cover: null,
              tags: [],
              createdAt: data.building.createdAt,
              updatedAt: data.building.updatedAt
            };

            // Update cache
            buildings.push({
              buildingInformation: newBuilding,
              canManageAccessGroups: true,
              canManageBuildingUsers: true,
              hasWriteAccess: true,
              isBuildingUser: true
            });

            // Update user store
            const user = useUserStore();
            user.buildings.set(newBuilding.id, {
              building: newBuilding,
              canManageAccessGroups: true,
              canManageBuildingUsers: true,
              hasWriteAccess: true,
              isBuildingUser: true
            });

            return userCacheResponse;
          }
        }
      })
    })
    .then(response => response.data);
};

export const searchBuildings = (data: Partial<SearchRequest>) => {
  return axios
    .post<
      SearchResponse<
        Omit<Building, "buildingFacilityInformationId"> & {
          facilityInformation: BuildingFacilityInformation | null;
        }
      >
    >("api/administration/Building/search", {
      pageNumber: data.pageNumber || 1,
      pageSize: data.pageSize || 100,
      criteria: data.criteria || [],
      field: data.field || "name",
      order: data.order || SortOrder.Ascending
    })
    .then(response => response.data);
};

export const getBuildingEnergyData = async (data: {
  buildingId: Guid;
  groupBy: Period;
  startDate: Date;
  endDate: Date;
}) => {
  const user = useUserStore();

  return axios
    .post<EnergyResponse>(
      "api/data/external/Building/energy",
      {
        id: data.buildingId,
        start: convertToTimeZone(data.startDate, user.getBuildingTimeZone(data.buildingId)),
        end: convertToTimeZone(data.endDate, user.getBuildingTimeZone(data.buildingId)),
        groupBy: data.groupBy
      },
      {
        cache: useCache({
          cachePredicate: {
            responseMatch: ({ data }) => !!data.data
          }
        })
      }
    )
    .then(response => response.data);
};

export const getWeatherData = (
  data: Omit<GetBuildingWeatherDataRequest, "showWeather" | "rollup">
) => {
  const user = useUserStore();

  return axios
    .post<GetBuildingWeatherDataResponse, GetBuildingWeatherDataRequest>(
      "api/data/external/Building/weather",
      {
        id: data.id,
        start: convertToTimeZone(data.start, user.getBuildingTimeZone(data.id)),
        end: convertToTimeZone(data.end, user.getBuildingTimeZone(data.id)),
        showWeather: true,
        rollup: 60
      },
      { cache: useCache() }
    )
    .then(response => response.data);
};

export const getBuildingEnergyRuntime = (buildingId: Guid, start: Date, end: Date) => {
  const user = useUserStore();

  return axios
    .post<EnergyRuntimeResponse>(
      "api/data/external/Building/energy-runtime",
      {
        id: buildingId,
        start: convertToTimeZone(start, user.getBuildingTimeZone(buildingId)),
        end: convertToTimeZone(end, user.getBuildingTimeZone(buildingId))
      },
      {
        cache: useCache({
          cachePredicate: {
            responseMatch: ({ data }) => Array.isArray(data.data) && data.data.length > 0
          }
        })
      }
    )
    .then(response => response.data);
};

export const getBuilding = (buildingId: Guid) => {
  return axios
    .get<GetBuildingResponse>(`api/administration/Building/${buildingId}`)
    .then(response => response.data);
};

export const findEanMeteringPoints = (data: GetMeteringPointsRequest) => {
  return axios
    .post<
      GetMeteringPointsResponse,
      GetMeteringPointsRequest
    >("api/data/external/Building/ean-metering-points", data)
    .then(response => response.data);
};

export const updateBuilding = (
  building: UpdateBuildingRequest,
  config: CacheRequestConfig<UpdateBuildingResponse, UpdateBuildingRequest> = {}
) => {
  //Whitespace in front needs to be removed for sorting purposes
  building.label = building.label?.trim() || null;

  return axios
    .put<UpdateBuildingResponse, UpdateBuildingRequest>("api/administration/Building", building, {
      cache: useCache({
        update: {
          // Someone else might have changed some data for this building
          // so we need to update the building in the userStore and user cache
          // when we retrieve the projectData
          user: (userCacheResponse, updateBuildingResponse) => {
            if (userCacheResponse.state !== "cached") return "ignore";

            const userCache = userCacheResponse.data.data as AuthUserResponse;
            const buildings = userCache.data.buildingAccessInformation;

            const index = buildings.findIndex(
              ({ buildingInformation }) => buildingInformation.id === building.id
            );

            if (index < 0) {
              return "delete";
            }

            // Update cache
            buildings[index].buildingInformation.label = updateBuildingResponse.data.data.label;

            // Update user store
            const user = useUserStore();
            user.updateBuilding(building.id, buildings[index].buildingInformation);

            return userCacheResponse;
          },
          [`building:${building.id}`]: (buildingCacheResponse, updateBuildingResponse) => {
            if (buildingCacheResponse.state !== "cached") return "ignore";

            const buildingCache = buildingCacheResponse.data.data as GetProjectDataResponse;

            // Update cache
            const building = buildingCache.data.buildings[0];
            building.brandingId = updateBuildingResponse.data.data.brandingId;
            building.label = updateBuildingResponse.data.data.label;
            building.type = updateBuildingResponse.data.data.type;
            building.licenses = updateBuildingResponse.data.data.licenses;

            return buildingCacheResponse;
          }
        }
      }),
      ...config
    })
    .then(response => response.data);
};

export const createFacilityInformation = (data: CreateBuildingFacilityInformationRequest) => {
  return axios
    .post<
      CreateBuildingFacilityInformationResponse,
      CreateBuildingFacilityInformationRequest
    >("api/administration/BuildingFacilityInformation", data)
    .then(response => {
      return response.data;
    });
};

/**
 * When updating facilityInformation all possible data fields are required
 * even if they are not changed. Else the backend will set them to null.
 * @param facilityInformationId with all attributes and values
 * @param data
 */
export const updateFacilityInformation = (
  facilityInformationId: Guid,
  data: BuildingFacilityInformation
) => {
  return axios
    .put<CreateBuildingFacilityInformationResponse, UpdateBuildingFacilityInformationRequest>(
      "api/administration/BuildingFacilityInformation",
      {
        id: facilityInformationId,
        ...data
      }
    )
    .then(response => {
      return response.data;
    });
};

export const getSpacesByBuildingId = (buildingId: Guid) => {
  return axios
    .get<GetBuildingFloorsAndSpacesResponse>(`api/administration/Building/${buildingId}/spaces`, {
      cache: useCache({ ttl: 15 * 60 * 1000 })
    })
    .then(response => response.data);
};
