<template>
  <div v-if="!isHiddenForCurrentRoute && needRefresh" role="alertdialog" class="update-available">
    <h4 class="update-title text-base md:text-md">
      {{ $t("common.update-available.title") }}
    </h4>
    <p class="update-desc text-base font-medium">
      {{ $t("common.update-available.desc") }}
    </p>
    <base-button color="blue" class="w-40 min-w-max" @click="updateServiceWorker(true)">
      {{ $t("common.update-available.button") }}
    </base-button>
  </div>
</template>

<script setup>
import { ref, computed, watchEffect } from "vue";
import { useRoute } from "vue-router";
import { useRegisterSW } from "virtual:pwa-register/vue";
import BaseButton from "@/components/BaseButton.vue";

const route = useRoute();

const forceUpdate = ref(false);

const { needRefresh, updateServiceWorker } = useRegisterSW({
  async onRegisteredSW(swUrl, r) {
    const cachedVersionRes = await caches.match("/version.txt");
    const newVersion = await getVersionNumber();

    if (cachedVersionRes) {
      const cachedVersion = await cachedVersionRes.text();

      if (cachedVersion !== newVersion) {
        forceUpdate.value = true;
      }
    }

    caches.open("version").then(async cache => {
      cache.put("/version.txt", new Response(newVersion));
    });

    r &&
      setInterval(
        async () => {
          if (!(!r.installing && navigator)) return;

          if ("connection" in navigator && !navigator.onLine) return;

          const response = await fetch(swUrl, {
            cache: "no-store",
            headers: {
              cache: "no-store",
              "cache-control": "no-cache"
            }
          });

          if (response?.status === 200) {
            const newRegistration = await r.update();

            newRegistration.addEventListener("updatefound", () => {
              const newWorker = newRegistration.installing;

              newWorker.addEventListener("statechange", async () => {
                if (newWorker.state === "installed") {
                  const cachedVersionRes = await caches.match("/version.txt");
                  const cachedVersion = await cachedVersionRes.text();
                  const newVersion = await getVersionNumber();

                  if (cachedVersion !== newVersion) {
                    caches.open("version").then(async cache => {
                      cache.put("/version.txt", new Response(newVersion));
                    });

                    forceUpdate.value = true;
                  }
                }
              });
            });
          }
        },
        60 * 60 * 1000
      );
  }
});

watchEffect(() => {
  if (
    needRefresh.value &&
    ((isHiddenForCurrentRoute.value && route.name !== "UserLogin") || forceUpdate.value)
  ) {
    updateServiceWorker(true);
  }
});

async function getVersionNumber() {
  const versionTxt = await fetch("/version.txt", {
    cache: "no-store",
    headers: {
      cache: "no-store",
      "cache-control": "no-cache"
    }
  });

  return await versionTxt.text();
}

const isHiddenForCurrentRoute = computed(() => {
  return ["UserLogin", "HealthSpaceLive", "HealthBuildingLive"].includes(route.name);
});
</script>

<style scoped>
.update-available {
  @apply fixed bottom-6 right-6 z-[99999] w-[calc(100%-3rem)] bg-white px-5 py-4 shadow-lg md:w-[600px];

  .update-title {
    @apply mb-1 text-md;
  }

  .update-desc {
    @apply mb-3;
  }

  .update-button {
    @apply w-full md:w-auto;
  }
}
</style>
