import { defineStore } from "pinia";
import { useSessionStorage } from "@vueuse/core";
import * as api from "@/api";
import { useUserStore } from "@/stores/user";
import { BuildingType, DayOfWeek, License, SensorType, UtilityType } from "@/constants";
import { compareSensors, compareUtilities } from "@/utils/sort";
import { computed, ref } from "vue";
import type {
  Building,
  Connection,
  Floor,
  ProjectSettings,
  ProjectSettingsStandard,
  Sensor,
  SensorRule,
  Space
} from "@/types";
import { getCurrentYear } from "@/utils";

export const useBuildingStore = defineStore("building", () => {
  const user = useUserStore();

  // Identifiers
  const id = useSessionStorage<Building["id"]>("currentBuilding", "" as Guid);
  const projectId = ref<Building["projectId"]>("" as Guid);
  const brandingId = ref<Building["brandingId"]>("" as Guid);

  // Building data
  const name = ref<Building["name"]>("");
  const label = ref<Building["label"]>("");
  const addresses = ref<Building["addresses"]>([]);
  const licenses = ref<Building["licenses"]>([]);
  const areas = ref<Building["areas"]>([]);
  const cover = ref<Building["cover"]>(null);
  const logo = ref<Building["logo"]>(null);
  const buildingType = ref<Building["type"]>(BuildingType.Office);
  const tags = ref<Building["tags"]>([]);
  const timeZone = ref<string>("");

  // Other information
  const projectSettings = ref<ProjectSettings | null>(null);
  const floorsMap = ref(new Map<Guid, Floor>());
  const spacesMap = ref(new Map<Guid, Space>());
  const sensors = ref<Sensor[]>([]);
  const connections = ref<Connection[]>([]);

  // Getters
  const formattedName = computed(() => label.value || name.value);
  const isBuildingUser = computed(() => user.isBuildingUser(id.value));
  const hasWriteAccess = computed(() => user.hasWriteAccess(id.value));
  const canManageAccessGroups = computed(() => user.canManageAccessGroups(id.value));
  const canManageBuildingUsers = computed(() => user.canManageBuildingUsers(id.value));

  const floors = computed(() => Array.from(floorsMap.value.values()));
  const spaces = computed(() => Array.from(spacesMap.value.values()));
  const sensorTypes = computed(() =>
    [...new Set(sensors.value.map(sensor => sensor.name))]
      .filter(sensorType => Object.values(SensorType).includes(sensorType))
      .sort(compareSensors)
  );
  const utilityTypes = computed(() =>
    [...new Set(connections.value.map(connection => connection.type))]
      .filter(utilityType => Object.values(UtilityType).includes(utilityType))
      .sort(compareUtilities)
  );

  const projectSettingsId = computed(() => projectSettings.value?.id || null);
  const isBuildingDashboardPublic = computed(
    () => projectSettings.value?.isBuildingDashboardPublic || false
  );
  const isSpaceDashboardPublic = computed(
    () => projectSettings.value?.isSpaceDashboardPublic || false
  );
  const projectSettingsStandards = computed<ProjectSettingsStandard[]>(
    () => projectSettings.value?.projectSettingsStandards || []
  );
  const healthStandardYears = computed(() => projectSettingsStandards.value.map(s => s.year));
  const openingHours = computed(() => {
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"] as const; // prettier-ignore

    return new Map(
      days.map<[keyof typeof DayOfWeek, { start: string | null; end: string | null }]>(day => [
        day,
        projectSettings.value
          ? {
              start:
                projectSettings.value.openingHours[
                  `${day.toLowerCase() as Lowercase<typeof day>}StartHour`
                ],
              end: projectSettings.value.openingHours[
                `${day.toLowerCase() as Lowercase<typeof day>}EndHour`
              ]
            }
          : {
              start: null,
              end: null
            }
      ])
    );
  });

  // Actions
  const getBuildingInformation = (() => {
    let abortController = new AbortController();

    return (buildingId: Guid) => {
      abortController.abort();
      abortController = new AbortController();

      const building = user.getBuilding(buildingId) as Building | undefined;

      return building
        ? api.getProjectData(building.projectId, {
            id: `building:${buildingId}`,
            signal: abortController.signal
          })
        : Promise.reject("Could not find building");
    };
  })();

  return {
    // State
    id,
    projectId,
    brandingId,
    name,
    label,
    addresses,
    licenses,
    areas,
    cover,
    logo,
    buildingType,
    connections,
    tags,
    timeZone,

    // Getters
    formattedName,
    isBuildingUser,
    hasWriteAccess,
    canManageAccessGroups,
    canManageBuildingUsers,
    floors,
    spaces,
    sensorTypes,
    utilityTypes,
    projectSettingsId,
    isBuildingDashboardPublic,
    isSpaceDashboardPublic,
    projectSettingsStandards,
    healthStandardYears,
    openingHours,
    getFloor: (floorId: Guid) => floorsMap.value.get(floorId),
    getSpace: (spaceId: Guid) => spacesMap.value.get(spaceId),
    getSpacesByFloor: (floorId: Guid) => spaces.value.filter(space => space.floorId === floorId),
    hasLicense: (license: License) => licenses.value.includes(license),
    getHealthStandard: (year = getCurrentYear()) =>
      projectSettingsStandards.value.find(s => s.year === year),

    // Actions
    load: async (buildingId: Guid) => {
      return Promise.allSettled([
        getBuildingInformation(buildingId),
        api.getUserConnections(buildingId)
      ]).then(([projectResult, connectionsResult]) => {
        if (projectResult.status === "rejected") {
          return api.isAborted(projectResult.reason)
            ? undefined
            : Promise.reject("Could not find building");
        }

        const project = projectResult.value.data;
        const building = project.buildings[0];

        id.value = building.id;
        projectId.value = project.id;
        brandingId.value = building.brandingId;

        name.value = building.name;
        label.value = building.label;
        addresses.value = building.addresses;
        licenses.value = building.licenses;
        areas.value = building.areas;
        cover.value = building.cover;
        logo.value = building.logo;
        buildingType.value = building.type;
        tags.value = building.tags;
        timeZone.value = building.timezone;

        projectSettings.value = project.settings;

        const floors = project.floors?.sort((a, b) => a.name.localeCompare(b.name)) || [];
        const spaces = project.spaces?.sort((a, b) => a.name.localeCompare(b.name)) || [];

        floorsMap.value = new Map(floors.map(floor => [floor.id, floor]));
        spacesMap.value = new Map(spaces.map(space => [space.id, space]));
        sensors.value = project.sensors;

        connections.value = connectionsResult.status === "fulfilled" ? connectionsResult.value : [];
      });
    },
    update: async (
      updatedFields: Partial<Pick<Building, "label" | "type" | "licenses" | "brandingId">>
    ) => {
      return api
        .getBuilding(id.value)
        .then(response => {
          const data = response?.data || {};

          return api.updateBuilding({
            id: data.id,
            projectId: data.projectId,
            brandingId: updatedFields.brandingId ?? data.brandingId,
            buildingFacilityInformationId: data.facilityInformation?.id,
            name: data.name,
            referenceId: data.referenceId,
            country: data.country,
            address: data.address,
            timezone: data.timezone,
            label: updatedFields.label ?? data.label,
            type: updatedFields.type ?? data.type,
            licenses: updatedFields.licenses ?? data.licenses
          });
        })
        .then(() => {
          brandingId.value = updatedFields.brandingId ?? brandingId.value;
          label.value = updatedFields.label ?? label.value;
          buildingType.value = updatedFields.type ?? buildingType.value;
          licenses.value = updatedFields.licenses ?? licenses.value;
        });
    },
    updateProjectSettings: (settings: Partial<ProjectSettings>) => {
      const buildingId = id.value;
      Promise.resolve()
        .then(() => {
          if (projectSettings.value) {
            return api.updateProjectSettings({
              id: projectSettings.value.id,
              projectId: projectId.value,
              openingHours: settings.openingHours || projectSettings.value.openingHours,
              isBuildingDashboardPublic:
                settings.isBuildingDashboardPublic ??
                projectSettings.value.isBuildingDashboardPublic,
              isSpaceDashboardPublic:
                settings.isSpaceDashboardPublic ?? projectSettings.value.isSpaceDashboardPublic
            });
          } else {
            return api.addProjectSettings({
              projectId: projectId.value,
              openingHours: settings.openingHours,
              isBuildingDashboardPublic: settings.isBuildingDashboardPublic,
              isSpaceDashboardPublic: settings.isSpaceDashboardPublic
            });
          }
        })
        .then(response => {
          api.storage.remove(`building:${buildingId}`);
          projectSettings.value = {
            ...response.data,
            projectSettingsStandards: projectSettingsStandards.value
          };
        });
    },
    updateHealthStandard: (year: number, sensorRules: SensorRule[]) => {
      const currentHealthStandard = projectSettings.value?.projectSettingsStandards.find(
        _ => _.year === year
      );

      if (currentHealthStandard) {
        currentHealthStandard.sensorRules = sensorRules;
      } else {
        projectSettings.value?.projectSettingsStandards.push({
          year,
          sensorRules
        });
      }
    },
    removeSpace: (spaceId: Guid) => {
      spacesMap.value.delete(spaceId);
    },
    $reset: () => {
      id.value = "" as Guid;
      projectId.value = "" as Guid;

      name.value = "";
      label.value = "";
      addresses.value = [];
      licenses.value = [];
      areas.value = [];
      cover.value = null;
      logo.value = null;
      buildingType.value = BuildingType.Office;

      projectSettings.value = null;
      floorsMap.value = new Map<Guid, Floor>();
      spacesMap.value = new Map<Guid, Space>();
      sensors.value = [];
      connections.value = [];
    }
  };
});
