<template>
  <div class="h-full">
    <router-view />

    <update-available class="z-50" />

    <base-toast />
  </div>
</template>

<script setup lang="ts">
import UpdateAvailable from "@/components/UpdateAvailable.vue";
import BaseToast from "@/components/BaseToast.vue";
import { useAnalyticsPageviews } from "@/composables/useAnalyticsPageviews";

useAnalyticsPageviews();
</script>
