import { License } from "@/constants";
import { isEqualObjects } from "@/utils";
import { isToday } from "date-fns";
import type {
  RouteLocationNormalized,
  LocationQueryRaw,
  RouteRecordName,
  Router,
  NavigationFailure
} from "vue-router";

interface QueryState {
  date: number;
  shared: {
    [key in License]: {
      queryParams: Array<string>;
      query: LocationQueryRaw;
    };
  };
  pages: {
    [key in RouteRecordName]: LocationQueryRaw;
  };
}

const queryState: QueryState = {
  date: new Date().getTime(),
  shared: {
    [License.ESG]: {
      queryParams: [],
      query: {}
    },
    [License.Health]: {
      queryParams: ["date-start", "date-end", "date-range"],
      query: {}
    },
    [License.Bms]: {
      queryParams: ["date-start", "date-end"],
      query: {}
    }
  },
  pages: {}
};

function resetState() {
  queryState.date = new Date().getTime();
  queryState.shared[License.ESG].query = {};
  queryState.shared[License.Health].query = {};
  queryState.shared[License.Bms].query = {};
  queryState.pages = {};

  localStorage.removeItem("queryState");
}

export const initializeQueryParams = () => {
  const savedQueryState = localStorage.getItem("queryState");

  if (savedQueryState) {
    try {
      const parsedState = JSON.parse(savedQueryState);

      if (parsedState && isToday(parsedState.date)) {
        Object.assign(queryState, parsedState);
      } else {
        localStorage.removeItem("queryState");
      }
    } catch (e) {
      console.error(e);
    }
  }
};

export const saveQuery = (to: RouteLocationNormalized) => {
  if (to.name === "UserLogin") {
    resetState();
    return;
  } else if (to.name === "EsgEanCodebook" || to.meta.breadcrumbs.includes("admin")) {
    return;
  }

  const license = to.meta?.requiredLicense;
  const query = to.query;

  if (to.name) {
    queryState.pages[to.name] = query;
  }

  if (typeof license === "number" && queryState.shared[license]) {
    for (const param of queryState.shared[license].queryParams) {
      if (param in query) {
        queryState.shared[license].query[param] = query[param];
      }
    }
  }

  localStorage.setItem("queryState", JSON.stringify(queryState));
};

export const getUpdatedQuery = (to: RouteLocationNormalized) => {
  if (to.name === "UserLogin") return null;

  const license = to.meta?.requiredLicense;
  const pageQuery = queryState.pages[to.name || ""] || {};
  const sharedQuery =
    license !== null && license in queryState.shared ? queryState.shared[license].query : {};
  const useWhitelist = Array.isArray(to.meta?.queryParams);

  const query = {
    ...pageQuery,
    ...sharedQuery,
    ...to.query
  };

  if (useWhitelist) {
    for (const param of Object.keys(query)) {
      if (!to.meta.queryParams?.includes(param)) {
        delete query[param];
      }
    }
  }

  return isEqualObjects(to.query, query) ? null : query;
};

let updatePromise: Promise<void | NavigationFailure | undefined> = Promise.resolve();

export const updateRouteQuery = (router: Router, query: LocationQueryRaw) => {
  updatePromise = updatePromise.then(() => {
    const route = router.currentRoute.value;

    // Delete saved page query
    if (route.name) delete queryState.pages[route.name];

    // Delete saved shared query parameters
    const license = route.meta?.requiredLicense;
    const sharedQuery =
      license !== null && license in queryState.shared ? queryState.shared[license].query : {};

    for (const param of Object.keys(query)) {
      delete sharedQuery[param];
    }

    // Merge query parameters of current route with new query
    const newQuery = { ...route.query, ...query };

    // Delete keys from newQuery that are undefined, null or empty strings
    for (const param of Object.keys(newQuery)) {
      if (newQuery[param] === undefined || newQuery[param] === null || newQuery[param] === "") {
        delete newQuery[param];
      }
    }

    return router.replace({ query: newQuery });
  });
};
