import type { RouteRecordRaw } from "vue-router";
import { createRouter, createWebHistory } from "vue-router";
import routes from "@/router/routes";
import { useAuthStore } from "@/stores/auth";
import { useUserStore } from "@/stores/user";
import { useBuildingStore } from "@/stores/building";
import { AccessLevel, License } from "@/constants";
import { until } from "@vueuse/core";
import { getUpdatedQuery, initializeQueryParams, saveQuery } from "./queryParams";
import { hasAccessToPageWithLicense } from "@/utils/routing";
import { useGeneralStore } from "@/stores/general";

export const router = createRouter({
  history: createWebHistory(),
  routes: routes as Array<RouteRecordRaw>,
  scrollBehavior: async (to, from, savedPosition): Promise<any> => {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

initializeQueryParams();

// Save query params on route change
router.afterEach(to => saveQuery(to));

// Update query params on route change
router.beforeEach((to, from) => {
  // Don't update query params on initial load
  if (!from.name) return;

  const query = getUpdatedQuery(to);

  if (query) {
    return { path: to.path, query };
  }

  setNewTagVisited(to.name?.toString());
});

// Check if user is logged in before routing
router.beforeEach(async to => {
  if (to.meta.minimumAccessLevel === AccessLevel.Public && to.name !== "UserLogin") {
    return;
  }

  const auth = useAuthStore();
  const user = useUserStore();

  await auth.lockTokenRefresh(() => Promise.resolve());
  await until(() => user.id || !auth.isLoggedIn).toBeTruthy();

  if (!auth.isLoggedIn && auth.refreshToken) {
    await auth.refreshTokens();
  }

  if (!auth.isLoggedIn && to.name !== "UserLogin") {
    return { name: "UserLogin", query: { ...to.query, redirect: to.path } };
  }
});

router.beforeEach(to => {
  if (to.name === "UserWelcome") return;

  const user = useUserStore();

  if (!user.settings?.isOnBoarded && user.id) {
    return { name: "UserWelcome" };
  }
});

// Start loading building data before routing
router.beforeEach(async (to, from) => {
  if (to.meta.minimumAccessLevel === AccessLevel.Public) return;

  const building = useBuildingStore();
  const user = useUserStore();

  try {
    if (to.params.buildingId && to.params.buildingId !== from.params.buildingId) {
      await building.load(to.params.buildingId as Guid);
    } else if (!user.buildings.has(building.id) && user.sortedBuildings.length) {
      await building.load(user.sortedBuildings[0].id);
    } else if (!from.name && user.buildings.has(building.id)) {
      await building.load(building.id);
    }
  } catch (error) {
    if (!from.name || to.params.buildingId) {
      return {
        path: "/404",
        query: { fromUrl: to.fullPath }
      };
    }

    return false;
  }
});

// Check if user has access to building before routing
router.beforeEach(to => {
  if (to.meta.requiredLicense === null) return;

  const building = useBuildingStore();
  const buildingId = to.params.buildingId || building.id;

  if (!buildingId) {
    return { path: "/" };
  }

  if (hasAccessToPageWithLicense(to)) {
    return;
  }

  switch (to.meta.requiredLicense) {
    case License.ESG:
      return { name: "EsgLandingPage", params: { buildingId } };
    case License.Health: {
      if (to.name === "AiPlayground")
        return { name: "AiPlaygroundLandingPage", params: { buildingId } };

      return { name: "HealthLandingPage", params: { buildingId } };
    }
    case License.Bms:
      return { name: "BmsLandingPage", params: { buildingId } };
    default:
      return "/";
  }
});

function setNewTagVisited(name: string | undefined) {
  const general = useGeneralStore();

  if (name === undefined || name === null) return;

  const visitedWatchers = general.visitedWatchers;

  if (visitedWatchers[name] !== undefined) {
    visitedWatchers[name] = true;
  }

  general.updateVisitedWatchers(visitedWatchers);
}
