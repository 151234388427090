import { AccessLevel } from "@/constants";
import { routeMeta, hasAccess } from "@/utils/routing";
import type { RouteRecordRaw } from "vue-router";

const AdminBuildings = () => import("@/views/AdminBuildings.vue");
const AdminProjects = () => import("@/views/AdminProjects.vue");
const AdminTest = () => import("@/views/AdminTest.vue");
const AdminUsers = () => import("@/views/AdminUsers.vue");
const AdminConnections = () => import("@/views/AdminConnections.vue");
const AdminUtilitySuppliers = () => import("@/views/AdminUtilitySuppliers.vue");
const AdminMissingUtilityData = () => import("@/views/AdminMissingUtilityData.vue");
const AdminTags = () => import("@/views/AdminTags.vue");

const routes: RouteRecordRaw[] = [
  {
    path: "/admin/buildings",
    name: "AdminBuildings",
    component: AdminBuildings,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.Admin,
      breadcrumbs: ["admin"]
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/admin/projects",
    name: "AdminProjects",
    component: AdminProjects,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.Admin,
      breadcrumbs: ["admin"]
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/admin/test",
    name: "AdminTest",
    component: AdminTest,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.Admin,
      breadcrumbs: ["admin"]
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/admin/users",
    name: "AdminUsers",
    component: AdminUsers,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.Admin,
      breadcrumbs: ["admin"]
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/admin/utility-connections",
    name: "AdminConnections",
    component: AdminConnections,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.Admin,
      breadcrumbs: ["admin"]
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/admin/utility-suppliers",
    name: "AdminUtilitySuppliers",
    component: AdminUtilitySuppliers,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.Admin,
      breadcrumbs: ["admin"]
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/admin/missing-utility-data",
    name: "AdminMissingUtilityData",
    component: AdminMissingUtilityData,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.Admin,
      breadcrumbs: ["admin"]
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/admin/tags",
    name: "AdminTags",
    component: AdminTags,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.Admin,
      breadcrumbs: ["admin"]
    }),
    beforeEnter: [hasAccess]
  }
];

export default routes;
