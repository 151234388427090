import type {
  BuildingUserInvite,
  BulkAcceptBuildingUserInviteRequest,
  BulkAcceptBuildingUserInviteResponse,
  BulkCreateBuildingUserInviteRequest,
  BulkCreateBuildingUserInviteResponse,
  BulkRevokeBuildingUserInviteRequest,
  BulkRevokeBuildingUserInviteResponse,
  GetBuildingUserInvitesResponse,
  SearchRequest
} from "@/types";
import { axios, useCache } from "../axios";
import { SortOrder } from "@/constants";
import type { SearchResponse } from "@/types/api";
import type { CacheRequestConfig } from "axios-cache-interceptor";

export const getBuildingUserInvites = (
  config: CacheRequestConfig<GetBuildingUserInvitesResponse> = {}
) => {
  return axios
    .get<GetBuildingUserInvitesResponse>("api/BuildingUserInvite", {
      cache: useCache({ ttl: 15 * 60 * 1000 }),
      ...config
    })
    .then(response => response.data);
};

export const inviteBuildingUsers = (data: BulkCreateBuildingUserInviteRequest) => {
  return axios
    .post<
      BulkCreateBuildingUserInviteResponse,
      BulkCreateBuildingUserInviteRequest
    >("api/BuildingUserInvite", data)
    .then(response => response.data);
};

export const searchBuildingUserInvites = (buildingId: Guid, request: Partial<SearchRequest>) => {
  return axios
    .post<SearchResponse<BuildingUserInvite>, SearchRequest>(
      `api/BuildingUserInvite/${buildingId}/search`,
      {
        pageNumber: request.pageNumber || 1,
        pageSize: request.pageSize || 100,
        criteria: request.criteria || [],
        field: request.field || "User.Email",
        order: request.order || SortOrder.Ascending
      }
    )
    .then(response => response.data);
};

export const revokeBuildingUserInvites = (buildingId: Guid, inviteIds: Guid[]) => {
  return axios
    .post<BulkRevokeBuildingUserInviteResponse, BulkRevokeBuildingUserInviteRequest>(
      `api/BuildingUserInvite/${buildingId}/revoke`,
      {
        inviteIds
      }
    )
    .then(response => response.data);
};

export const acceptBuildingUserInvites = (inviteIds: Guid[]) => {
  return axios
    .post<BulkAcceptBuildingUserInviteResponse, BulkAcceptBuildingUserInviteRequest>(
      "api/BuildingUserInvite/accept",
      {
        invites: inviteIds.reduce<BulkAcceptBuildingUserInviteRequest["invites"]>(
          (acc, inviteId) => {
            acc[inviteId] = true;
            return acc;
          },
          {}
        )
      }
    )
    .then(response => response.data);
};

export const declineBuildingUserInvites = (inviteIds: Guid[]) => {
  return axios
    .post<BulkAcceptBuildingUserInviteResponse, BulkAcceptBuildingUserInviteRequest>(
      "api/BuildingUserInvite/accept",
      {
        invites: inviteIds.reduce<BulkAcceptBuildingUserInviteRequest["invites"]>(
          (acc, inviteId) => {
            acc[inviteId] = false;
            return acc;
          },
          {}
        )
      }
    )
    .then(response => response.data);
};
