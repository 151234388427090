import buildingRoutes from "./building";
import esgRoutes from "./esg";
import healthRoutes from "./health";
import bmsRoutes from "./bms";
import reportsRoutes from "./reports";
import adminRoutes from "./admin";
import userRoutes from "./user";
import errorRoutes from "./errors";
import type { RouteRecordRaw } from "vue-router";

const routes: RouteRecordRaw[] = [
  ...buildingRoutes,
  ...esgRoutes,
  ...healthRoutes,
  ...bmsRoutes,
  ...reportsRoutes,
  ...adminRoutes,
  ...userRoutes,
  ...errorRoutes
];

export default routes;
