import Axios, { type AxiosError } from "axios";

export const isUnauthorized = (error: AxiosError | unknown) => {
  if (!Axios.isAxiosError(error)) return false;

  return error.response?.status === 401;
};

export const isForbidden = (error: AxiosError | unknown) => {
  if (!Axios.isAxiosError(error)) return false;

  return error.response?.status === 403;
};

export const isServerError = (error: AxiosError | unknown) => {
  if (!Axios.isAxiosError(error)) return false;

  return !!error.response && error.response.status >= 500;
};

export const isAborted = (error: AxiosError | unknown) => {
  if (!Axios.isAxiosError(error)) return false;

  return Axios.isCancel(error);
};

export const isNetworkError = (error: AxiosError | unknown) => {
  if (!Axios.isAxiosError(error)) return false;

  return !error.response && error.request;
};

export const isError = (error: AxiosError | unknown, statusCode: number) => {
  if (!Axios.isAxiosError(error)) return false;

  return error.response?.status === statusCode;
};

export const isUnknownError = (error: AxiosError | unknown) => {
  return Axios.isAxiosError(error);
};
