import type {
  Connection,
  GetUserConnectionsResponse,
  SearchRequest,
  UpdateConnectionRequest,
  UpdateConnectionResponse
} from "@/types";
import { axios, useCache } from "../axios";
import { SortOrder } from "@/constants";
import type { SearchResponse } from "@/types/api";
import type { CacheRequestConfig } from "axios-cache-interceptor";

export const updateConnection = async (data: UpdateConnectionRequest) => {
  return axios
    .put<UpdateConnectionResponse, UpdateConnectionRequest>("api/administration/Connection", data)
    .then(response => response.data);
};

export const deleteConnection = (connectionId: Guid) => {
  return axios.delete<undefined>(`api/administration/Connection/${connectionId}`);
};

export const searchConnections = (data: Partial<SearchRequest>) => {
  return axios
    .post<SearchResponse<Connection>, SearchRequest>("api/administration/Connection/search", {
      pageNumber: data.pageNumber || 1,
      pageSize: data.pageSize || 100,
      criteria: data.criteria || [],
      field: data.field || "ean",
      order: data.order || SortOrder.Ascending
    })
    .then(response => response.data);
};

export const getUserConnections = (
  buildingId: Guid,
  config: CacheRequestConfig<GetUserConnectionsResponse> = {
    cache: useCache({
      cachePredicate: {
        responseMatch: ({ data }) => !!data.mainMeters || !!data.subMeters
      }
    })
  }
) => {
  return axios
    .get<GetUserConnectionsResponse>("api/data/internal/Connection/userConnections", {
      params: { buildingId },
      ...config
    })
    .then(({ data }) => [...(data.mainMeters || []), ...(data.subMeters || [])]);
};
