import { AccessLevel, License } from "@/constants";
import { checkLicenseAndRoute, hasAccess, routeMeta } from "@/utils/routing";
import type { RouteRecordRaw } from "vue-router";

const BuildingOverview = () => import("@/views/BuildingOverview.vue");
const BuildingDetail = () => import("@/views/BuildingDetail.vue");
const BuildingTimeline = () => import("@/views/BuildingTimeline.vue");
const BuildingInvites = () => import("@/views/BuildingInvites.vue");
const FileManager = () => import("@/views/FileManager.vue");
const BuildingSettingsGeneral = () => import("@/views/BuildingSettingsGeneral.vue");
const BuildingSettingsHealthStandards = () => import("@/views/BuildingSettingsHealthStandards.vue");
const BuildingSettingsTechnical = () => import("@/views/BuildingSettingsTechnical.vue");
const BuildingSettingsAccess = () => import("@/views/BuildingSettingsAccess.vue");
const AiPlayground = () => import("@/views/AiPlayground.vue");
const AiPlaygroundLandingPage = () => import("@/views/AIPlaygroundLandingPage.vue");
const BuildingSettingsAdmin = () => import("@/views/BuildingSettingsAdmin.vue");

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    alias: "/buildings",
    name: "BuildingOverview",
    component: BuildingOverview,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.LoggedIn
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/buildings/:buildingId",
    name: "BuildingDetail",
    component: BuildingDetail,
    props: true,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.BuildingAccessGroupUser
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/buildings/:buildingId/timeline",
    name: "BuildingTimeline",
    component: BuildingTimeline,
    props: true,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.Admin,
      breadcrumbs: ["building"]
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/building-invites",
    name: "BuildingInvites",
    component: BuildingInvites,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.LoggedIn
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/buildings/:buildingId/ai-playground",
    name: "AiPlayground",
    component: AiPlayground,
    props: true,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.Admin,
      requiredLicense: License.Health,
      breadcrumbs: ["building"]
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/buildings/:buildingId/playground",
    name: "AiPlaygroundLandingPage",
    component: AiPlaygroundLandingPage,
    props: true,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.Admin,
      queryParams: []
    }),
    beforeEnter: [hasAccess, to => checkLicenseAndRoute(to, "AiPlayground")]
  },
  {
    path: "/buildings/:buildingId/files",
    name: "FileManager",
    component: FileManager,
    props: true,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.BuildingAccessGroupUser,
      breadcrumbs: ["building"]
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/buildings/:buildingId/settings",
    redirect: { name: "BuildingSettingsGeneral" }
  },
  {
    path: "/buildings/:buildingId/settings/general",
    name: "BuildingSettingsGeneral",
    component: BuildingSettingsGeneral,
    props: true,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.BuildingUser,
      breadcrumbs: ["building"]
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/buildings/:buildingId/settings/labels-and-benchmarks",
    redirect: { name: "BuildingSettingsHealthStandards" }
  },
  {
    path: "/buildings/:buildingId/settings/health-standards",
    name: "BuildingSettingsHealthStandards",
    component: BuildingSettingsHealthStandards,
    props: true,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.BuildingUser,
      breadcrumbs: ["building"]
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/buildings/:buildingId/settings/contact-and-technical-details",
    name: "BuildingSettingsTechnical",
    component: BuildingSettingsTechnical,
    props: true,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.BuildingUser,
      breadcrumbs: ["building"]
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/buildings/:buildingId/settings/access",
    name: "BuildingSettingsAccess",
    component: BuildingSettingsAccess,
    props: true,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.BuildingAccessGroupUser,
      breadcrumbs: ["building"]
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/buildings/:buildingId/settings/admin",
    name: "BuildingSettingsAdmin",
    component: BuildingSettingsAdmin,
    props: true,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.Admin,
      breadcrumbs: ["building"]
    }),
    beforeEnter: [hasAccess]
  }
];

export default routes;
