import { tw } from "@/utils/index";

export const config = {
  themes: {
    info: {
      $extend: "tooltip",
      triggers: ["hover"],
      autoHide: true,
      placement: "bottom",
      popperClass: tw`mx-2 max-w-64`
    }
  }
};
