import { nextTick } from "vue";
import { createI18n, type NamedValue, type I18n } from "vue-i18n";
import { until } from "@vueuse/core";
import { Language } from "@/constants";
import { setDefaultOptions } from "date-fns";
import { nl, enUS } from "date-fns/locale";

let i18n: I18n<{}, {}, {}, Language, false> | null = null;

async function loadLocaleMessages(locale: Language) {
  const messages = await import(`@/locales/${locale}.json`);

  // set locale and locale message
  i18n?.global.setLocaleMessage(locale, messages.default);

  return nextTick();
}

const setI18nLanguage = (locale: Language) => {
  if (i18n !== null) {
    i18n.global.locale.value = locale;
  }

  document.documentElement.setAttribute("lang", locale);

  // Set up global date-fns options
  const locales = { [Language.nl_NL]: nl, [Language.en_US]: enUS };
  setDefaultOptions({
    locale: locales[locale],
    weekStartsOn: 0
  });
};

export const setupI18n = async (locale = import.meta.env.VITE_APP_I18N_LOCALE) => {
  i18n = createI18n<false>({
    legacy: false,
    locale: locale,
    fallbackLocale: import.meta.env.VITE_APP_I18N_LOCALE,
    silentTranslationWarn: import.meta.env.MODE === "production"
  });

  await changeLocale(locale);

  return i18n;
};

/** Wait until i18n instance has been set up */
export const waitForI18n = async () => {
  return await until(() => i18n !== null).toBeTruthy();
};

/**
 * Translation method that can be used outside SFCs
 * Important: make sure i18n instance has been set up before usage
 * e.g. by using the 'waitForI18n()' method
 */
export const translate = (key: string, options: NamedValue = {}, defaultMessage: string = key) => {
  if (i18n !== null) {
    return i18n.global.t(key, options, defaultMessage);
  }

  return defaultMessage;
};

export async function changeLocale(locale: Language) {
  try {
    await loadLocaleMessages(locale);
    setI18nLanguage(locale);
  } catch (e) {
    console.error(e);
  }
}
