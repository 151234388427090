import type { PrimeVueConfiguration, PrimeVueLocaleOptions } from "primevue/config";
import { tw } from "@/utils";
import { Language } from "@/constants";

export const config = (locale: Language): PrimeVueConfiguration => ({
  unstyled: true,
  pt: {
    toast: {
      root: {
        class: ["w-96 z-50"]
      },
      container: "bg-white my-2 rounded w-full border border-tw-grey shadow-sm",
      content: "flex items-center py-2 px-4",
      icon: ({ props }) => ({
        class: [
          props.message?.severity,
          "size-6",
          {
            "text-blue-light": props.message?.severity == "info",
            "text-green": props.message?.severity == "success",
            "text-orange": props.message?.severity == "warn",
            "text-red": props.message?.severity == "error"
          }
        ]
      }),
      text: "text-base font-normal flex flex-col flex-1 grow shrink ml-4",
      summary: ({ props }) => ({
        class: [
          "text-sm font-medium block",
          {
            "text-blue-light": props.message?.severity == "info",
            "text-green": props.message?.severity == "success",
            "text-orange": props.message?.severity == "warn",
            "text-red": props.message?.severity == "error"
          }
        ]
      }),
      detail: "text-xs block text-fiord",
      closeButton: "flex group/close",
      closeIcon: "text-bombay group-hover/close:text-fiord size-6 duration-75",
      transition: {
        enterFromClass: "opacity-0 translate-x-0 translate-y-2/4 translate-z-0",
        enterActiveClass: "transition-transform transition-opacity duration-200",
        leaveFromClass: "max-h-40",
        leaveActiveClass: "transition-all duration-200 ease-in-out",
        leaveToClass: "max-h-0 opacity-0 mb-0 overflow-hidden"
      }
    },
    selectbutton: {
      root: tw`inline-flex flex-wrap pl-px pt-px text-sm`,
      button: ({ context }) => ({
        class: [
          tw`-ml-px -mt-px flex items-center justify-center border border-tw-grey px-3 py-2 text-sm leading-6 outline-none`,
          context.disabled ? tw`cursor-not-allowed opacity-25` : tw`cursor-pointer`,
          { "bg-cloudy": context.active },
          {
            "focus:bg-athens hover:bg-athens active:bg-cloudy": !context.disabled && !context.active
          }
        ]
      }),
      label: tw`text-sm`
    },
    autocomplete: {
      root: tw`focus-ring inline-flex border border-tw-grey text-sm leading-6`,
      input: ({ props }) => ({
        class: [
          tw`w-full read-only:cursor-pointer read-only:bg-cloudy focus:outline-none focus:ring-0 disabled:cursor-default disabled:bg-cloudy`,
          { " px-3 py-2": !props.multiple }
        ]
      }),
      container: tw`flex w-full flex-wrap gap-1 p-2`,
      token: tw`group/token pointer relative inline-flex items-center hover:cursor-pointer`,
      removeTokenIcon: tw`absolute size-full opacity-0`,
      loadingIcon: tw`m-auto flex h-full`,
      inputToken: tw`grow`,
      dropdownButton: {
        root: tw`flex`,
        label: tw`hidden`
      },
      panel: tw`absolute left-0 top-0 mt-2 overflow-auto bg-white py-1 text-base shadow-md ring-1 ring-black/5 focus:outline-none sm:text-sm`,
      item: ({ context }) => ({
        class: [
          tw`cursor-pointer px-3 py-2 hover:bg-cloudy disabled:bg-fiord`,
          { "bg-tw-grey": context.focused }
        ]
      }),
      emptyMessage: tw`px-3 py-2`,
      searchResultMessage: tw`px-3 py-2`,
      selectedMessage: tw`px-3 py-2`,
      hooks: {},
      transition: {
        enterActiveClass: "transition ease-out duration-100",
        enterFromClass: "transform opacity-0 scale-95",
        enterToClass: "transform opacity-100 scale-100",
        leaveActiveClass: "transition ease-in duration-75",
        leaveFromClass: "transform opacity-100 scale-100",
        leaveToClass: "transform opacity-0 scale-95"
      }
    },
    dropdown: {
      input: tw`outline-none`,
      panel: tw`mt-1 max-h-60 overflow-y-auto bg-white py-1 text-base shadow-md ring-1 ring-black/5 focus:outline-none sm:text-sm`,
      item: ({ context }) => ({
        class: [
          tw`cursor-pointer px-3 py-2 hover:bg-athens active:bg-cloudy`,
          {
            "bg-athens": context.focused,
            "text-fiord": context.disabled
          }
        ]
      }),
      trigger: tw`h-6`,
      transition: {
        enterActiveClass: "transition ease-out duration-100",
        enterFromClass: "transform opacity-0 scale-95",
        enterToClass: "transform opacity-100 scale-100",
        leaveActiveClass: "transition ease-in duration-75",
        leaveFromClass: "transform opacity-100 scale-100",
        leaveToClass: "transform opacity-0 scale-95"
      }
    }
  },
  // Make sure i18n instance has been set up before usage
  locale: locales[locale]
});

// prettier-ignore
export const locales: Record<Language, PrimeVueLocaleOptions> = {
  [Language.en_US]: {
    searchMessage: "{0} result is available | {0} results are available",
    selectionMessage: "{0} item selected | {0} items selected",
    emptySelectionMessage: "No item selected",
    emptySearchMessage: "No results found",
    fileSizeTypes: ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
    monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  },
  [Language.nl_NL]: {
    searchMessage: "{0} resultaat beschikbaar | {0} resultaten beschikbaar",
    selectionMessage: "{0} optie geselecteerd | {0} opties geselecteerd",
    emptySelectionMessage: "Geen optie geselecteerd",
    emptySearchMessage: "Geen resultaten gevonden",
    fileSizeTypes: ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    dayNames: ["zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag"],
    dayNamesShort: ["zon", "ma", "di", "woe", "do", "vrij", "zat"],
    dayNamesMin: ["zo", "ma", "di", "wo", "do", "vr", "za"],
    monthNames: ["januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"],
    monthNamesShort: ["jan", "feb", "mar", "apr", "mei", "jun", "jul", "aug", "sep", "okt", "nov", "dec"]
  }
};
