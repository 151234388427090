import { watch } from "vue";
import { useRouter, type RouteParams } from "vue-router";
import { useUserStore } from "@/stores/user";
import { useBuildingStore } from "@/stores/building";
import { plausible } from "@/utils/analytics";

/* Track pageview on route change */
export const useAnalyticsPageviews = () => {
  const router = useRouter();
  const user = useUserStore();
  const building = useBuildingStore();

  watch(
    router.currentRoute,
    (newRoute, oldRoute) => {
      if (
        newRoute.name === oldRoute?.name &&
        isSameParams(newRoute.params, oldRoute?.params ?? {})
      ) {
        return;
      }

      plausible.trackPageview(undefined, {
        props: JSON.parse(
          JSON.stringify({
            userId: user.id,
            userRole: user.role,
            userLanguage: user.settings?.language ?? document.documentElement.lang,
            pageName: newRoute.name,
            buildingId: newRoute.params.buildingId,
            isBuildingUser: newRoute.params.buildingId ? building.isBuildingUser : undefined,
            hasWriteAccess: newRoute.params.buildingId ? building.hasWriteAccess : undefined,
            query: window.location.search.substring(1)
          })
        )
      });
    },
    { immediate: true }
  );

  function isSameParams(params: RouteParams, otherParams: RouteParams) {
    return (
      Object.keys(params).length === Object.keys(otherParams).length &&
      Object.keys(params).every(key => {
        return String(params[key]) === String(otherParams[key]);
      })
    );
  }
};
