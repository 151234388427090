import { axios } from "../axios";
import type {
  CreateUserSettingsRequest,
  CreateUserSettingsResponse,
  UpdateUserSettingsRequest,
  UpdateUserSettingsResponse
} from "@/types";

export const addUserSettings = (
  data: Omit<CreateUserSettingsRequest, "timezone" | "alertInterval">
) => {
  return axios
    .post<
      CreateUserSettingsResponse,
      CreateUserSettingsRequest
    >("api/administration/UserSettings", { ...data, timezone: "Europe/Amsterdam", alertInterval: 1.5 })
    .then(response => response.data);
};

export const updateUserSettings = (
  data: Omit<UpdateUserSettingsRequest, "timezone" | "alertInterval">
) => {
  return axios
    .put<
      UpdateUserSettingsResponse,
      UpdateUserSettingsRequest
    >("api/administration/UserSettings", { ...data, timezone: "Europe/Amsterdam", alertInterval: 1.5 })
    .then(response => response.data);
};
