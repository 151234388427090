/**
 * TODO: Remove buildingId variable from routes
 */

import { AccessLevel } from "@/constants";
import { routeMeta, hasAccess } from "@/utils/routing";
import type { RouteRecordRaw } from "vue-router";

const ReportOverview = () => import("@/views/ReportOverview.vue");
const ReportEnergyLabels = () => import("@/views/ReportEnergyLabels.vue");

const routes: RouteRecordRaw[] = [
  {
    path: "/buildings/:buildingId/reports",
    redirect: { name: "ReportOverview" }
  },
  {
    path: "/reports",
    name: "ReportOverview",
    component: ReportOverview,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.LoggedIn,
      breadcrumbs: ["building"]
    }),
    beforeEnter: [hasAccess]
  },
  {
    path: "/buildings/reports/energy-labels",
    redirect: { name: "ReportEnergyLabels" }
  },
  {
    path: "/reports/energy-labels",
    name: "ReportEnergyLabels",
    component: ReportEnergyLabels,
    meta: routeMeta({
      minimumAccessLevel: AccessLevel.LoggedIn
    }),
    beforeEnter: [hasAccess]
  }
];

export default routes;
